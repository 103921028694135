import React, { Component } from "react"
import { graphql } from 'gatsby'
import SEO from "../components/seo"

import Project from "../components/project"
import Menu from "../components/menu"

import "react-awesome-button/dist/themes/theme-rickiest.css"

class PortfolioPage extends Component {
  render() {
    const { data } = this.props;
    const projects = data.allMarkdownRemark.edges
    return (
      <>
        <SEO title="Portfolio" />
        <div className="portfolio-text">
          <h1>My projects</h1>
          <p>
            Over the years, I’ve had the pleasure to work with dozens of really
            great people and companies on some really amazing projects.
            Unfortunately, I'm not able to display some of my works
            due to the contracts, so I decided to only display some of my side
            projects! If you would like to see more of my works or something a
            little more specific, please get in touch and I'd be happy to send
            some details to you!
          </p>
        </div>
        <div className="portfolio-container">
          {projects.map(({ node }) => {
            const title = node.frontmatter.title
            return (
              <Project
                title={title}
                slug={node.frontmatter.slug}
                screenshot={`/project/${node.frontmatter.slug}.jpg`}
                href={`/project/${node.frontmatter.slug}`}
              />
            )
          })}
        </div>
        <Menu active="portfolio" />
      </>
    )
  }
}

export default PortfolioPage

export const pageQuery = graphql`
         query {
           allMarkdownRemark(
             sort: { fields: [frontmatter___id], order: DESC }
           ) {
             edges {
               node {
                 frontmatter {
                   title
                   slug
                 }
               }
             }
           }
         }
       `
