import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import ProjectImage from "./ProjectImage"
import { AwesomeButton } from "react-awesome-button"

import posed from "react-pose"

const ScrollDown = posed.div({
  off: {
    y: -300,
    scale: 0,
  },
  on: {
    y: 0,
    opacity: 1,
    scale: 1,
  },
});

class Project extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMouseInside: false,
      showProject: false,
    }
  }

  mouseEnter = () => {
    this.setState({
      isMouseInside: true,
    })
  }

  mouseLeave = () => {
    this.setState({
      isMouseInside: false,
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        showProject: true,
      })
    }, 200)
  }

  render() {
    const screenshot_container = {
      display: "flex",
      justifyContent: "center",
    }
    return (
      <ScrollDown pose={this.state.showProject ? "on" : "off"}>
        <div className="p-item">
          <div class="mac-window" role="link" onMouseLeave={this.mouseLeave} tabIndex={0}>
            <div class="mac-titlebar">
              <span class="close mac-titlebar-button "></span>
              <span class="min mac-titlebar-button "></span>
              <span class="max mac-titlebar-button "></span>
              <div class="title">{this.props.title}</div>
            </div>
            <div
              class="console-text"
              aria-label="Read me"
              role="button"
              tabIndex={0}
              onMouseEnter={this.mouseEnter}
              style={screenshot_container}
            >
              <ProjectImage
                imgName={`${this.props.slug}-1.jpg`}
                style={
                  this.state.isMouseInside
                    ? { opacity: "0.7" }
                    : { opacity: "1" }
                }
              />
            </div>
            {this.state.isMouseInside ? (
              <AwesomeButton type="link">
                <Link to={this.props.href}>Read more</Link>
              </AwesomeButton>
            ) : null}
          </div>
        </div>
      </ScrollDown>
    )
  }
}

export const query = graphql`
  query {
    file(relativePath: { eq: "blog/avatars/kyle-mathews.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Project
