import React from 'react'
import {
  StaticQuery,
  graphql
} from 'gatsby';
import Img from 'gatsby-image';

const ProjectImage = ({
  imgName, style
}) => (<StaticQuery query={
  graphql`
        query {
          allImageSharp {
            edges {
              node {
                fluid(maxWidth: 450, quality: 50) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
        }
      `
}
  render={
    data => {
      const image = data.allImageSharp.edges.find(
        edge => edge.node.fluid.originalName === imgName
      )
      if (!image) {
        return null
      }
      return <Img style={style} fluid={
        image.node.fluid
      }
      />
    }
  }
/>
  );

export default ProjectImage